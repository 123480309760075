@import 'style';
@import 'styles/mixins';

.newscard {
	display: block;

	&.mod-featured {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&:not([href]) {
		cursor: initial;
	}

	&[href]:hover,
	&[href]:focus-visible {
		.newscard-image-bg {
			transform: scale(1.05) translateZ(0);
		}
	}

	&-image {
		overflow: hidden;
		position: relative;
		width: 100%;
		display: block;
		aspect-ratio: 346/154;
		box-shadow: 0 0 8px rgb(0 0 0 / 10%);

		.mod-featured & {
			@include media($ipad-land) {
				flex: 1 1 auto;
			}
		}

		&-bg {
			backface-visibility: hidden;
			transform: scale(1) translateZ(0);
			transition: all 0.3s ease-out;
		}
	}

	&-content {
		display: block;
		padding-top: 10px;

		&-date {
			color: var(--primary);
			font-size: 18px;
			line-height: calc(21 / 18);
			font-weight: 400;
		}

		&-title {
			@include m-lineclamp(3, 28px);
		}

		&-date + &-title {
			margin-top: 5px;
		}
	}
}
