@import 'style';

.title {
	font-weight: 600;
	word-break: break-word;

	&.mod-h1 {
		max-width: 560px;
		font-size: 24px;
		line-height: 28px;
		color: var(--primary);
		transition: color 0.3s ease-out;

		@include media($tablet) {
			font-size: 36px;
			line-height: 40px;
		}

		@include media($ipad-land) {
			font-size: 46px;
			line-height: 50px;
		}
	}

	&.mod-h2 {
		font-size: 24px;
		line-height: 28px;

		@include media($ipad-port) {
			font-size: 36px;
			line-height: 40px;
		}

		@include media($ipad-land) {
			font-size: 40px;
			line-height: 44px;
		}
	}

	&.mod-h3 {
		font-size: 24px;
		line-height: 28px;

		@include media($ipad-land) {
			font-size: 36px;
			line-height: 40px;
		}
	}

	&.mod-h4 {
		font-size: 24px;
		line-height: 28px;
	}

	&.mod-h5 {
		font-size: 20px;
		line-height: 1.5;
	}

	&.mod-inherit {
		color: inherit;
	}

	&.mod-uppercase {
		text-transform: uppercase;
	}
}
