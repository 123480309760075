@import 'style';

.result {
	position: relative;
	&-item {
		display: inline-block;
		padding-bottom: 3px;
		border-bottom: 1px solid transparent;
		transition: border-color 0.3s;
		&:hover {
			border-color: var(--link);
		}
	}
}
