@import 'style';

.club {
	position: relative;

	&-content {
		@include content-line;

		&-title,
		&-image {
			margin-bottom: 40px;
		}

		&-image {
			position: relative;
			height: 200px;
			max-width: 200px;
		}

		&-info {
			&-title {
				font-size: 18px;
				font-weight: 600;
				color: var(--link);
			}

			&:global(.wysiwyg) {
				h2,
				h3,
				h4,
				h5 {
					color: var(--link);
				}
			}

			& + & {
				margin-top: 20px;
			}
		}
	}
}
