@import 'style';

.header {
	position: relative;
	z-index: 2;

	&.mod-subnavigation {
		background-color: var(--primary);
	}

	&-row {
		min-height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&-left,
		&-right {
			display: flex;
			align-items: center;
		}

		&-left + &-right {
			margin-left: 30px;
		}
	}
}

.logo {
	display: inline-block;
	vertical-align: middle;

	svg {
		height: 26px;
		display: block;
	}
}

.button {
	display: none;
	background-color: var(--primary);
	color: black;
	padding: 6px 12px;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	transition: 0.3s ease-out;

	@include media($ipad-land) {
		display: block;
	}

	.mod-subnavigation & {
		background-color: white;
	}

	&:hover,
	&:focus-visible {
		color: white;
		background-color: black;
	}
}

.search {
	font-size: 18px;
	margin-right: 20px;
	transition: color 0.3s ease-out;

	@include media($ipad-land) {
		margin-right: 0;
	}

	&:hover,
	&:focus-visible {
		color: var(--primary);

		.mod-subnavigation & {
			color: white;
		}
	}
}

.button + .search {
	margin-left: 30px;
}
