@import 'style';

.educations {
	position: relative;

	&-introcontent {
		margin-bottom: 30px !important;

		&-intro {
			margin-top: 35px;
		}
	}

	&-content {
		&-wrapper {
			@include content-line;
		}
	}
}
