@import 'style';

.filters {
	display: flex;
	flex-direction: column;

	&-inputs {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		padding: 48px 0;

		@include media($ipad-land) {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&-buttons {
		display: flex;
		margin-left: auto;
		padding-bottom: 60px;

		&-reset {
			background: none;
			border: none;
			padding: 0;
			margin-right: 20px;
			font-size: 14px;
			color: #e6641e;
			text-decoration: underline;
			cursor: pointer;
		}

		&-submit {
			width: 120px;
			height: 40px;
			border: none;
			background: #e6641e;
			color: white;
			font-size: 14px;
			font-weight: 700;
			cursor: pointer;
			transition: opacity 0.2s;

			&:hover {
				opacity: 0.8;
			}
		}
	}
}
