.events {
	&-title {
		font-size: 18px;
		font-weight: 600;
		color: var(--link);
	}

	&-item {
		&-year {
			background-color: var(--primary);
			display: inline-block;
			vertical-align: top;
			margin-top: 10px;
			margin-bottom: 5px;
			padding: 4px 28px;
			color: var(--link);
			font-weight: 400;
		}

		&-event {
			display: block;

			&-title {
				font-weight: 400;
				color: var(--link);
			}
		}
	}
}
