@import 'styles/mixins';

.topbarcard {
	display: block;

	&:not([href]) {
		cursor: initial;
	}

	&[href]:hover,
	&[href]:focus-visible {
		.topbarcard-image-bg {
			transform: scale(1.05) translateZ(0);
		}
	}

	&-image {
		overflow: hidden;
		position: relative;
		width: 100%;
		display: block;
		aspect-ratio: 264/89;
		background-color: white;
		box-shadow: 0 0 8px rgb(0 0 0 / 10%);

		&.is-fullwidth {
			aspect-ratio: 1080/182;
		}

		&-bg {
			backface-visibility: hidden;
			transform: scale(1) translateZ(0);
			transition: all 0.3s ease-out;
		}
	}

	&-content {
		display: block;

		&-title {
			@include m-lineclamp(1, 24px);
		}
	}
}
