@import 'style';

.ads {
	&-items {
		&.mod-length2,
		&.mod-length3 {
			gap: 20px;
			display: grid;
			align-items: center;

			@include media($ipad-port) {
				grid-template-columns: repeat(2, 1fr);
			}
		}

		&-item {
			&:nth-child(1) {
				.mod-length3 & {
					@include media($ipad-port) {
						grid-row: span 2;
					}
				}
			}

			&-image {
				position: relative;
				width: 100%;
				height: 250px;
				display: block;
				background-color: var(--grey-light);
			}
		}
	}
}
