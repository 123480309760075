@import 'style';

.home {
	position: relative;
}

.homenews {
	margin: 40px 0;

	&.mod-news_only {
		margin-top: 0;
	}

	&-content {
		@include content-line;

		&-title {
			color: var(--primary);
			margin-bottom: 35px;
		}

		&-grid {
			display: grid;
			grid-gap: 25px 20px;

			@include media($tablet) {
				grid-template-columns: repeat(2, 1fr);
			}

			@include media($ipad-land) {
				grid-template-columns: repeat(3, 1fr);
			}

			&-item {
				position: relative;

				&.mod-featured {
					@include media($tablet) {
						grid-column: span 2;
					}

					@include media($ipad-land) {
						grid-row: span 2;
						grid-column: span 2;
					}
				}
			}
		}
	}

	&-link {
		margin-top: 10px;
	}
}
