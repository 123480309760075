@import 'style';

.embla {
	position: relative;
	margin: 0 auto;
	max-width: 560px;

	&-viewport {
		overflow: hidden;
		width: 100%;

		&:global(.is-draggable) {
			cursor: move;
			cursor: grab;
		}

		&:global(.is-dragging) {
			cursor: grabbing;
		}
	}

	&-slides {
		display: flex;
		align-items: center;
		user-select: none;
		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-webkit-tap-highlight-color: transparent;

		&-slide {
			min-width: 100%;
			opacity: 0.5;
			transition: opacity 0.25s ease-out;

			&:global(.is-selected) {
				opacity: 1;
			}
		}
	}

	&-pagination {
		margin-top: 20px;
		text-align: center;
	}
}
