.embedsocial {
	position: relative;

	iframe {
		display: block;
		margin: 0 auto;
		height: 490px;
		width: 100%;
		max-width: 327px;
	}
}
