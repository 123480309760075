.icon {
	height: 1em;
	line-height: 1;
	display: block;

	> svg {
		width: auto !important;
		height: 100% !important;
	}

	svg,
	path:not(.raw) {
		fill: currentColor !important;
	}
}
