.breadcrumbs {
	position: relative;
	margin-top: 20px;
	margin-bottom: 40px;

	&-parent,
	&-divider,
	&-title {
		color: #767676;
		font-size: 12px;
		line-height: 1.25;
		font-weight: 400;
		font-style: italic;
		display: inline-block;
		vertical-align: middle;
	}

	&-divider {
		margin: 0 4px;
	}

	&-parent {
		border-bottom: 1px solid transparent;
		transition: 0.3s ease-out;

		&:hover,
		&:focus-visible {
			color: black;
			border-color: black;
		}
	}

	&-title {
		font-weight: 600;
		text-transform: uppercase;
	}
}
