@import 'style';

@keyframes iframe_loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loading {
	@include content-line;
	height: 170px;
	margin-top: -2.5rem;
	&-spinner {
		border-radius: 100%;
		border-top: solid rgba(8, 0, 0, 0.1);
		border-right: solid rgba(8, 0, 0, 0.1);
		border-bottom: solid rgba(8, 0, 0, 0.1);
		animation: iframe_loader 1.1s infinite linear;
		position: absolute;
		left: 50%;
		height: 50px;
		width: 50px;
		margin-left: -25px;
		border-width: 4px;
		border-left: 4px solid var(--primary);
		top: 70px;
	}
}
