@import 'style';

.wysiwyg {
	position: relative;
	word-break: break-word;

	* + * {
		margin-top: 20px;
	}

	* + {
		h1,
		h2,
		h3 {
			margin-top: 40px;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: 600;
		word-break: break-word;

		+ * {
			margin-top: 10px;
		}
	}

	h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 20px;
	}

	h4 {
		font-size: 22px;
	}

	h5 {
		font-size: 18px;
	}

	b,
	strong {
		font-weight: 600;
	}

	a {
		color: var(--link);
		font-weight: 400;
		display: inline;
		border-bottom: 1px solid currentColor;
		transition: border 0.3s ease-out;

		&:hover {
			border-color: transparent;
		}
	}

	* + ol,
	* + ul {
		margin-top: 5px;
	}

	ol,
	ul {
		padding-left: 20px;

		li + li {
			margin-top: 10px;
		}
	}

	blockquote {
		color: var(--primary);
		font-size: 24px;
		line-height: 28px;
		font-weight: 400;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	:global(.wysiwyg-table) {
		overflow-x: auto;

		&::-webkit-scrollbar {
			height: 10px;
		}

		&::-webkit-scrollbar-track {
			background: #eee;
		}

		&::-webkit-scrollbar-thumb {
			background: #bdbdbd;
		}
	}

	table {
		float: none;
		height: auto !important;
		border-spacing: 0;
		border-collapse: collapse;

		& + * {
			margin-top: 60px;
		}

		thead + tbody {
			border-top: 1px solid #eeeeee;
		}

		thead {
			tr {
				text-align: left;
			}

			th,
			td {
				color: #2b2e34;
				font-weight: 600;
				white-space: nowrap;
			}
		}

		tbody {
			th {
				color: #2b2e34;
				font-weight: 600;
				text-align: left;
				white-space: nowrap;
			}

			tr:nth-child(even) {
				background-color: hsl(var(--primary-hsl), 0.25);
			}
		}

		th,
		td {
			padding: 10px;
			height: auto !important;

			&[rowspan] {
				border-right: 3px solid var(--primary);
			}
		}

		&:not(:global(.full-width-table-with-custom-column-sizes)) {
			width: auto !important;

			th,
			td {
				width: fit-content !important;
			}
		}
	}

	img {
		height: auto;
		display: block;
		max-width: 100%;
	}

	iframe {
		display: block;
		max-width: 100%;

		&[src^="https://www3.sport.vlaanderen"]
		{
			@include media('<557px') {
				height: 442px + 500px;
			}
		}
	}
}
