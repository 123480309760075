@import 'style';

.list {
	&-content {
		@include content-line;

		&-intro {
			margin-top: 15px;
		}
	}
}
