.cookiepolicy {
	position: relative;
	margin-top: 20px;
	margin-bottom: 60px;

	&-content {
		margin-top: 40px;

		a {
			color: var(--link);
			font-weight: 400;
			display: inline;
			border-bottom: 1px solid currentColor;
			transition: border 0.3s ease-out;

			&:hover {
				border-color: transparent;
			}
		}
	}
}
