@import 'style';

.calendargroups {
	position: relative;

	&-loadpast {
		cursor: pointer;
		color: var(--link);
		line-height: 1;
		font-weight: 600;
		display: inline-block;
		vertical-align: top;
		margin-bottom: 20px;
		border-bottom: 1px solid transparent;
		transition: border 0.3s ease-out;

		&:hover,
		&:focus-visible {
			border-color: currentColor;
		}
	}
}
