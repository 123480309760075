.checkbox {
	position: relative;
	display: flex;
	align-items: center;

	& + & {
		margin-top: 2px;
	}

	&-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}

	&-input:focus-visible + &-checkbox + &-label {
		color: var(--secondary);
	}

	&-input:checked + &-checkbox:before {
		transform: scale(1);
	}

	&-checkbox {
		cursor: pointer;
		width: 12px;
		height: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		border: 1px solid currentColor;

		&:before {
			content: '✓';
			font-size: 18px;
			line-height: 1;
			position: relative;
			top: -4px;
			left: 2px;
			transform: scale(0);
			transition: transform 0.3s ease-out;
		}
	}

	&-label {
		cursor: pointer;
		padding-left: 8px;
		transition: color 0.3s ease-out;

		&:hover {
			color: var(--secondary);
		}
	}
}
