@import 'style';

.herocategory {
	&-block {
		position: relative;

		&.mod-image {
			background-color: var(--primary);
			padding: 20px;
			color: white;

			@include media($ipad-land) {
				min-height: 282px;
				display: flex;
				align-items: flex-end;
			}
		}

		&-logos {
			position: relative;
			margin-top: -10px;

			@include media($ipad-land) {
				position: absolute;
				top: 20px;
				left: 20px;
			}

			&-link {
				line-height: 1;
				display: inline-block;
				vertical-align: middle;
				margin-top: 10px;

				svg {
					height: 30px;
					display: block;
				}
			}

			&-divider {
				width: 2px;
				height: 32px;
				display: inline-block;
				vertical-align: middle;
				margin: 10px 15px 0 15px;
				background-color: white;
			}
		}

		&-content {
			position: relative;
			padding-top: 60px;

			@include media($ipad-land) {
				max-width: calc((630 / 1085) * 100%);
			}

			&-date {
				font-size: 30px;
				line-height: 1;
				margin-bottom: 5px;
			}
		}
	}
}
