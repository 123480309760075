@import 'style';

.prev,
.next {
	display: none;

	@include media($desktop) {
		width: 44px;
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid var(--secondary);
		transition: 0.25s ease-out;
	}

	&.mod-absolute {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	&-icon {
		color: var(--secondary);
		font-size: 20px;
		transition: color 0.25s ease-out;
	}

	&:hover {
		border-color: transparent;
		background-color: var(--secondary);

		.prev-icon,
		.next-icon {
			color: white;
		}
	}

	&[disabled] {
		pointer-events: none;
		cursor: not-allowed;
		opacity: 0.5;
	}
}

.prev {
	&.mod-absolute {
		left: -44px - 20px;
	}
}

.next {
	&.mod-absolute {
		right: -44px - 20px;
	}
}

.pagination {
	display: block;

	&-item {
		width: 32px;
		height: 4px;
		display: inline-block;
		vertical-align: middle;
		background-color: hsl(var(--secondary-hsl), 0.25);
		transition: background 0.25s ease-out;

		&.is-active {
			background-color: var(--secondary);
		}

		& + & {
			margin-left: 8px;
		}
	}
}
