.image {
	margin: 0 auto;
	max-width: 560px;

	&-img {
		position: relative;
		display: block;
		aspect-ratio: 16/9;
	}

	&-text {
		font-size: 14px;
		padding: 5px;
		padding-bottom: 0;
	}
}
