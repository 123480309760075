@import 'style';

.athletes {
	&-content {
		@include content-line;

		&-intro {
			margin-top: 15px;
		}
	}
}

.overview {
	margin-top: 60px - 30px;
	margin-left: -20px;
	margin-bottom: 60px;

	&-item {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		margin-top: 30px;
		padding-left: 20px;

		@include media($tablet) {
			width: 50%;
		}

		@include media($ipad-land) {
			width: calc(100% / 3);
		}
	}
}
