.newstags {
	&-title {
		font-size: 12px;
		font-weight: 700;
		margin-bottom: 10px;
	}

	&-items {
		margin-top: -8px;
		margin-left: -8px;

		&-item {
			display: inline-block;
			vertical-align: top;
			margin-top: 8px;
			padding-left: 8px;

			&-tag {
				background-color: var(--primary);
				display: block;
				padding: 3px 9px 5px 9px;
				color: white;
				font-weight: 400;
			}
		}
	}
}
