@import 'style';

$breakpoint: $ipad-land;

.contentside {
	position: relative;
	margin-bottom: 60px;

	&-row {
		@include media($breakpoint) {
			display: flex;
			align-items: flex-start;
		}
	}
}

.content + .sidebar,
.sidebar + .content {
	margin-top: 60px;

	@include media($breakpoint) {
		margin-top: 0;
		margin-left: 20px;
	}
}

.content {
	max-width: 100%;
	@include content-line;

	@include media($breakpoint) {
		flex: 1 1 auto;
	}
}

.sidebar {
	width: 100%;
	max-width: 346px;
	margin: 0 auto;

	@include media($breakpoint) {
		flex: 0 0 auto;
	}

	&:empty {
		display: none;

		@include media($breakpoint) {
			display: block;
		}
	}
}
