@import 'style';

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}

.imageloader {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	background-color: var(--grey-light);
	background-size: 200% 100%;
	background-image: linear-gradient(
		to right,
		var(--grey-light) 8%,
		#fff 18%,
		var(--grey-light) 28%
	);
	animation: 1.5s shine linear infinite;
}
