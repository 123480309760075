.news {
	position: relative;

	&-tag {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 10px;
		margin: 20px 0;
		font-size: 15px;
		font-weight: 600;
		text-transform: uppercase;

		&-label {
			background-color: var(--primary);
			padding: 3px 9px 5px 9px;
			color: white;
		}
	}
}
