@import 'style';

.subnavigation {
	position: relative;
	display: none;

	@include media($ipad-land) {
		display: block;
		margin-bottom: 20px;
	}

	&-items {
		margin-top: -10px;
		margin-left: -35px;

		&-item {
			display: inline-block;
			vertical-align: top;
			margin-top: 10px;
			padding-left: 35px;
			max-width: 160px + 35px;
			line-height: calc(17 / 15);

			&-link {
				position: relative;
				display: block;
				padding-top: 20px;
				font-size: 15px;
				font-weight: 400;
				text-transform: uppercase;
				word-break: break-word;

				&:before {
					pointer-events: none;
					content: '';
					position: absolute;
					z-index: 2;
					top: -4px;
					height: 4px;
					width: 100%;
					background-color: white;
					transform-origin: bottom right;
					transform: scaleX(0);
					transition: transform 0.3s ease-out;
				}

				&:hover,
				&:focus-visible,
				&.is-active {
					&:before {
						transform-origin: bottom left;
						transform: scaleX(1);
					}
				}

				&.is-active {
					font-weight: 600;
				}
			}
		}
	}
}
