.facilities {
	&-title {
		font-size: 18px;
		font-weight: 600;
		color: var(--link);
	}
}

.facility {
	line-height: 1.25;

	& + & {
		margin-top: 20px;
	}

	&-title {
		font-weight: 400;
		text-transform: uppercase;
	}

	&-report {
		gap: 4px;
		display: flex;
		flex-wrap: wrap;
	}

	&-check {
		display: flex;
		align-items: center;

		&-checkbox {
			width: 12px;
			height: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 2px;
			border-radius: 100%;
			border: 1px solid currentColor;

			&:before {
				content: '✓';
				font-size: 18px;
				line-height: 1;
				position: relative;
				top: -4px;
				left: 2px;
				transform: scale(0);
				transition: transform 0.3s ease-out;
			}

			&.is-active:before {
				transform: scale(1);
			}
		}

		&-label {
			padding-left: 8px;
		}
	}
}
