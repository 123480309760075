@import 'style';

.linkstyled {
	&-link {
		cursor: pointer;

		&:hover,
		&:focus-visible {
			.text-border {
				border-color: currentColor;
			}
		}
	}
}

.text {
	color: var(--link);
	line-height: 1;
	font-weight: 600;

	&-border {
		border-bottom: 1px solid transparent;
		transition: border 0.3s ease-out;
	}

	.mod-inherit & {
		color: inherit;
	}

	.mod-regular & {
		font-weight: 400;
	}
}
