@import 'style';

.cookiesmissing {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	padding: 30px;
	text-align: center;

	&-text + &-button {
		margin-top: 20px;
	}

	&-button {
		color: white;

		&:hover,
		&:focus-visible {
			background-color: lighten(--primary, 5%);
		}
	}
}
