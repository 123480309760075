@import 'style';

.partners {
	position: relative;
	margin-top: 50px;
	margin-bottom: 30px;

	&-content {
		@include media($ipad-land) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&-stamp {
		width: 77px;
		height: 34px;
		display: none;
		margin-right: 30px;
		background-image: repeating-linear-gradient(
			to right,
			var(--primary),
			var(--primary) 11px,
			rgba(white, 0) 11px,
			rgba(white, 0) 22px
		);

		@include media($ipad-land) {
			display: block;
		}
	}

	&-items {
		flex: 1 1 auto;
		max-width: 740px;
		margin-left: auto;

		&-row {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			margin-left: -10px;
		}
	}
}

.partner {
	max-width: 120px;
	padding-left: 10px;
	filter: grayscale(1);
	transition: filter 0.3s ease-out;

	&-img {
		width: auto;
		max-height: 60px;
	}

	&:not([href]) {
		cursor: initial;
	}

	&[href]:hover,
	&[href]:focus-visible {
		filter: grayscale(0);
	}
}
