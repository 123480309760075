@import 'style';

.calendargroup {
	& + & {
		margin-top: 30px;
	}

	&-items {
		position: relative;
	}
}
