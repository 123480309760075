@import 'style';

.calendar {
	position: relative;

	&-introcontent {
		margin-bottom: 0 !important;

		&-intro {
			margin-top: 35px;
		}
	}

	&-content {
		&-wrapper {
			padding-top: 60px;
			@include content-line;
		}
	}
}
