@import 'style';

.moreinfo {
	background-color: hsl(var(--primary-hsl), 0.15);
	padding: 20px;
	font-size: 14px;

	&-phone,
	&-email {
		color: var(--link);
	}
}
