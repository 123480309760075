@import 'style';

.results {
	position: relative;

	&-container {
		height: 80px;

		&-loader {
			border-radius: 100%;
			border-top: solid rgba(8, 0, 0, 0.1);
			border-right: solid rgba(8, 0, 0, 0.1);
			border-bottom: solid rgba(8, 0, 0, 0.1);
			animation: iframe_loader 1.1s infinite linear;
			position: absolute;
			left: 50%;
			height: 50px;
			width: 50px;
			margin-left: -25px;
			border-width: 4px;
			border-left: 4px solid #e6641e;
		}
	}
}

@keyframes iframe_loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.title {
	padding-bottom: 20px;
}

.table {
	overflow-x: auto;

	& table {
		width: 100%;
		border-collapse: collapse;
	}

	& thead {
		height: 40px;
	}

	& th {
		text-align: left;
		background-color: #32327d;
		color: white;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		padding: 0 15px;
	}

	& tr {
		height: 40px;
		font-size: 12px;
	}

	& td {
		padding: 0 15px;
	}

	& tr:nth-child(even) {
		background-color: #eeeeee;
	}

	& tr:nth-child(odd) {
		background-color: white;
	}
}

.pagination {
	&-wrapper {
		list-style-type: none;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		column-gap: 10px;
		padding: 20px 0;
	}

	&-prev,
	&-next {
		display: none;

		@include media($ipad-land) {
			margin: 0 40px;
			position: relative;
			display: flex;
			justify-content: center;
			width: 54px;
			background: white;
			height: 28px;
			line-height: 22px;
			font-weight: 900;
			font-size: 22px;
			text-decoration: none;
			color: black;
			border: none;
			cursor: pointer;

			img {
				position: absolute;
				left: 0;
				top: 0;
			}

			span {
				position: absolute;
				top: 0;
				left: 0;
				background-color: white;
				padding: 1px 15px 5px 15px;
				width: 54px;
				z-index: 2;

				&:hover {
					background-color: #c2c2d8;
				}
			}
		}
	}

	&-page {
		height: 32px;
		width: 32px;
		line-height: 32px;
		font-size: 14px;
		font-weight: 600;
		text-align: center;
		cursor: pointer;

		&-link {
			display: block;
		}

		&:hover {
			text-decoration: underline;
		}

		&.is-active {
			color: white;
			background: #93a2b7;
			cursor: default;
			text-decoration: none;
		}
	}

	&-button {
		&.is-disabled {
			display: none;
		}
	}
}

.label {
	background: #fafafa;
}
