@import 'style';

.button {
	position: relative;
	cursor: pointer;
	color: var(--link);
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	display: inline-flex;
	vertical-align: middle;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	background-color: var(--primary);
	border: 1px solid transparent;
	transition: all 0.3s ease-out;

	&:hover,
	&:focus-visible {
		color: white;
	}

	&:disabled {
		pointer-events: none;
	}
}

.text,
.icon {
	& + & {
		margin-left: 15px;
	}
}

.icon {
	flex: 0 0 auto;
	line-height: 1;
}
