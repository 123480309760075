@import 'style';

.newscontent {
	margin: 40px 0;

	&-content {
		@include content-line;

		&-title {
			color: var(--primary);
			margin-bottom: 35px;
		}
	}

	&-link {
		margin-top: 10px;
	}
}
