@import 'style';

.image {
	height: auto;
	display: block;
	max-width: 100%;
	opacity: 0;
	transition: 0.3s ease-out;

	&.is-loaded {
		opacity: 1;
	}
}
