@import 'style';

.footer {
	position: relative;
	padding-top: 35px;
	padding-bottom: 60px;
	background-color: #fafafa;

	&-stamp {
		position: absolute;
		top: calc(100% + 26px);
		left: 30px;
		width: 77px;
		height: 34px;
		background-image: repeating-linear-gradient(
			to right,
			white,
			white 11px,
			rgba(white, 0) 11px,
			rgba(white, 0) 22px
		);
	}

	&-row {
		margin-top: -30px;

		@include media($tablet) {
			display: flex;
			flex-wrap: wrap;
			margin-left: -30px;
		}

		@include media($desktop) {
			justify-content: space-between;
		}

		&-col {
			margin-top: 30px;

			@include media($tablet) {
				width: 50%;
				padding-left: 30px;
			}

			@include media($ipad-port) {
				width: calc(100% / 3);
			}

			@include media($ipad-land) {
				width: 25%;
			}

			@include media($desktop) {
				width: auto;
				max-width: 190px;
			}
		}
	}
}

.category {
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	border-bottom: 2px solid transparent;
	transition: border 0.3s ease-out;

	&:hover,
	&:focus-visible {
		border-color: currentColor;
	}
}

.subcategories {
	margin-top: 20px;

	&-item {
		& + & {
			margin-top: 12px;
		}

		&-link {
			color: #727272;
			font-size: 15px;
			font-weight: 400;
			border-bottom: 1px solid transparent;
			transition: 0.3s ease-out;

			&:hover,
			&:focus-visible {
				color: black;
				border-color: currentColor;
			}
		}
	}
}

.about {
	@include media($desktop) {
		max-width: 210px;
	}

	&-item + &-item {
		margin-top: 25px;
	}
}

.social {
	&-items {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: -10px;
		margin-left: -10px;

		&-item {
			margin-top: 10px;
			padding-left: 10px;

			&-link {
				width: 36px;
				height: 36px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				border: 1px solid var(--secondary);
				color: var(--secondary);
				font-size: 16px;
				transition: 0.3s ease-out;

				&:hover,
				&:focus-visible {
					background-color: var(--secondary);
					border-color: transparent;
					color: white;
				}
			}
		}
	}
}
