.video {
	position: relative;
	overflow: hidden;
	max-width: 100%;
	aspect-ratio: 16/9;

	&-wrapper {
		background-color: var(--grey-light);
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
