@import 'style';

.cards {
	&-items {
		margin-top: -25px;

		@include media($tablet) {
			display: flex;
			flex-wrap: wrap;
			margin-left: -20px;
		}

		&-item {
			margin-top: 25px;

			@include media($tablet) {
				padding-left: 20px;
				width: 50%;
			}

			@include media($ipad-land) {
				width: calc(100% / 3);
			}
		}
	}
}

.card {
	background-color: #f5f5f5;
	height: 100%;

	&-image {
		display: block;
		overflow: hidden;
		position: relative;
		aspect-ratio: 314/127;

		&-bg {
			backface-visibility: hidden;
			transform: scale(1) translateZ(0);
			transition: all 0.3s ease-out;
		}

		&:not([href]) {
			cursor: initial;
		}

		&[href]:hover,
		&[href]:focus-visible {
			.card-image-bg {
				transform: scale(1.05) translateZ(0);
			}
		}
	}

	&-content {
		padding: 25px;
		font-size: 14px;
		line-height: calc(18 / 14);

		&-text {
			margin-top: 15px;
		}

		&-links {
			margin-top: 15px;

			&-link + &-link {
				margin-top: 5px;
			}
		}
	}
}
