@import 'include-media/dist/_include-media.scss';

@function lighten($color, $lighten) {
	@return hsl(
		var(#{$color}-h),
		var(#{$color}-s),
		calc(var(#{$color}-l) + $lighten)
	);
}

@function darken($color, $lighten) {
	@return hsl(
		var(#{$color}-h),
		var(#{$color}-s),
		calc(var(#{$color}-l) - $lighten)
	);
}

$breakpoints: (
	tablet: 600px,
	ipad-port: 768px,
	ipad-land: 1024px,
	desktop: 1200px
);

$tablet: '>=tablet';
$ipad-port: '>=ipad-port';
$ipad-land: '>=ipad-land';
$desktop: '>=desktop';

@mixin content-line {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: -30px;
		width: 8px;
		height: 100%;
		background-color: var(--primary);

		@include media($desktop) {
			left: -38px;
		}
	}
}
