@import 'style';

.newsdetail {
	&-contentside {
		margin-top: 40px;
	}

	&-date {
		color: var(--primary);
		font-size: 18px;
		line-height: calc(21 / 18);
		font-weight: 400;
		text-transform: uppercase;
	}

	&-image {
		margin-top: 50px;

		&:not(.is-visible) {
			@include media($ipad-land) {
				display: none;
			}
		}
	}

	&-intro {
		font-size: 20px;
		font-weight: 400;
		margin-top: 35px;

		@include media($ipad-land) {
			font-size: 24px;
			line-height: calc(30 / 24);
		}
	}
}

.sidebar {
	margin-top: 30px;

	&-image {
		display: none;

		&.is-visible {
			@include media($ipad-land) {
				display: block;
				margin-bottom: 30px;
			}
		}
	}

	&-item + &-item {
		margin-top: 30px;
	}
}

.info {
	&-labelvalue {
		font-size: 14px;
	}
}
