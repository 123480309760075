.introimage {
	max-width: 700px;

	&-wrapper {
		position: relative;

		&-text {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 10px;
			background-color: rgba(black, 0.5);
			color: white;
			font-size: 14px;
			opacity: 0;
			transition: opacity 0.3s ease-out;
		}

		&:hover {
			.introimage-wrapper-text {
				opacity: 1;
			}
		}
	}

	&-copyright {
		font-size: 14px;
		margin-top: 5px;
	}
}
