@import 'style';

.fetcherform {
	display: flex;
	margin: 20px 0;

	&-search {
		position: relative;
		width: 100%;
		max-width: 240px;
		display: inline-block;
		vertical-align: top;

		&-icon {
			position: absolute;
			top: 50%;
			left: 10px;
			transform: translateY(-50%);
			color: white;
		}

		&-input {
			background-color: #e5e5e5;
			width: 100%;
			padding: 6px 10px;
			padding-left: 40px;
			color: var(--link);
			font-weight: 400;

			&:focus {
				outline-color: var(--primary);
			}
		}
	}

	&-submit {
		background-color: var(--primary);
		padding: 5px 20px;
		margin-left: 20px;
		color: white;
		font-weight: 600;
		text-transform: uppercase;
		transition: background 0.3s ease-out;

		&:hover {
			background-color: var(--secondary);
		}
	}
}
