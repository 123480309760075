@import 'style';

.fetcherresults {
	@include content-line;
	margin: 40px 0;
	max-width: 716px;
	&-title {
		&.mod-documents {
			max-width: unset;
		}
	}
}
