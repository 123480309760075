@import 'style';

.athlete {
	&-description {
		margin-top: 15px;
	}
}

.table {
	width: 100%;
	border-spacing: 4px;
	margin-top: 40px;

	tr {
		&:nth-child(odd) {
			background-color: var(--primary);
		}

		&:nth-child(even) {
			background-color: hsl(var(--primary-hsl), 0.25);
		}

		td {
			padding: 5px 10px;
		}
	}
}
