.refreshercoursedetail {
	&-date {
		color: var(--link);
		font-size: 18px;
		line-height: calc(21 / 18);
		font-weight: 600;
		text-transform: uppercase;
	}

	&-intro {
		margin-top: 15px;
	}

	&-registration {
		margin-top: 15px;
	}
}
