@import 'style';

.calendarmonth {
	background-color: var(--secondary);
	display: inline-block;
	vertical-align: top;
	width: 128px;
	margin-bottom: 6px;
	padding: 5px 15px;
	color: white;
	font-size: 18px;
	line-height: calc(21 / 18);
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;

	&[data-theme] {
		background-color: var(--primary);
	}

	&.is-past {
		opacity: 0.7;
	}
}
