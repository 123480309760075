@import 'style';

.newsoverview {
	position: relative;
	margin-top: -25px;

	@include media($tablet) {
		margin-left: -20px;
	}

	&-item {
		margin-top: 25px;

		@include media($tablet) {
			display: inline-block;
			vertical-align: top;
			width: 50%;
			padding-left: 20px;
		}

		@include media($ipad-land) {
			width: calc(100% / 3);
		}
	}
}
