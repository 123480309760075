@import 'style';

.calendaritem {
	&[data-theme] {
		.calendaritem {
			&-dateblock {
				background-color: var(--primary);
			}
			&-content {
				&-title {
					color: var(--primary);
				}

				&-link {
					color: var(--primary);
				}

				:global(.wysiwyg) {
					a {
						color: var(--primary);
					}
				}
			}
		}
	}

	&:not([data-theme]) {
		.calendaritem-moreinfo {
			background-color: hsl(var(--secondary-hsl), 0.15);
		}
	}

	@include media($ipad-port) {
		display: flex;
	}

	&-dateblock {
		flex: 0 0 128px;
		background-color: var(--secondary);
		display: flex;
		padding: 11px 20px;

		@include media($ipad-port) {
			display: block;
		}

		&:not([href]) {
			cursor: initial;
		}

		&-until {
			color: white;
			font-size: 30px;
			line-height: 1;
			font-weight: 400;
			padding: 0 10px;
			margin-top: 8px;

			@include media($ipad-port) {
				margin-bottom: 10px;
				margin-top: 0;
				padding: 0;
			}
		}

		&-date {
			color: white;
			font-size: 30px;
			line-height: 1;
			font-weight: 400;

			&-day {
				font-size: 12px;
			}

			& + & {
				margin-left: 20px;

				@include media($ipad-port) {
					margin-left: 0;
					margin-top: 10px;
				}
			}
		}
	}

	&.is-past {
		.calendaritem {
			&-dateblock {
				opacity: 0.7;
			}
		}
	}

	&-content {
		flex: 1 1 auto;
		padding: 20px;
		background-color: var(--grey-light);
		font-size: 14px;
		line-height: calc(18 / 14);

		@include media($ipad-port) {
			background-color: transparent;
		}

		&-title {
			color: var(--secondary);

			& + div {
				margin-top: 20px;
			}
		}

		&-link {
			color: var(--secondary);
		}

		:global(.wysiwyg) {
			a {
				color: var(--secondary);
			}
		}
	}

	&-moreinfo {
		@include media($tablet) {
			border-left: 116px solid var(--grey-light);
		}

		@include media($ipad-port) {
			flex: 0 0 auto;
			align-self: flex-start;
			border-left: none;
			margin-top: 20px;
			width: calc(100% / 3);
		}

		@include media($ipad-land) {
			width: calc((100% / 3) - 10px);
		}
	}

	& + & {
		margin-top: 30px;
	}
}
