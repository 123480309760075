.calendarWithFilters {
	&-filters {
		position: relative;
		max-width: 560px;
		margin-top: -30px;
		margin-bottom: 45px;
		margin-left: -60px;

		&-fieldset {
			margin: 0;
			padding: 0;
			border: none;
			display: inline-block;
			vertical-align: top;
			margin-top: 30px;
			padding-left: 60px;

			&-legend {
				font-size: 18px;
				line-height: calc(26 / 18);
				font-weight: 600;
			}

			&-subitems {
				margin-left: 20px;
			}
		}
	}

	&-groups {
		margin-top: 60px;
	}
}
