@import 'style';

.faqoverview {
	position: relative;
	max-width: 716px;
	margin-top: 30px;

	&-category {
		& + & {
			margin-top: 10px;
		}

		&-title {
			cursor: pointer;
			background-color: var(--primary);
			display: inline-block;
			vertical-align: top;
			padding: 5px 10px;
			font-size: 20px;
			line-height: 1.25;
			font-weight: 900;
			color: white;
			text-transform: uppercase;
			transition: 0.3s ease-out;
		}

		&[open] > .faqoverview-category-title {
			background-color: var(--secondary);
		}

		&-content {
			padding-top: 20px;
			padding-bottom: 40px;
		}
	}
}

.question {
	& + & {
		margin-top: 30px;
	}

	&-title {
		cursor: pointer;
		display: flex;
		font-size: 20px;
		line-height: 1.25;
		font-weight: 900;
		color: var(--secondary);

		&:before {
			content: '+';
			flex: 0 0 20px;
			color: var(--primary);
		}
	}

	&[open] > .question-title:before {
		content: '-';
	}

	&-text {
		margin-top: 15px;
		margin-left: 20px;

		@include media($ipad-land) {
			margin-left: 40px;
		}
	}
}
