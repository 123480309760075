@import 'style';

.topbar {
	position: relative;
	margin-top: 20px;
	margin-bottom: 40px;

	&-items {
		margin-top: -15px;
		margin-left: -10px;

		&-item {
			width: 100%;
			display: inline-block;
			vertical-align: top;
			margin-top: 15px;
			padding-left: 10px;

			//default
			@include media($tablet) {
				width: calc(100% / 3);

				&.is-fullwidth {
					width: 100%;
				}
			}

			// 1 item
			&:first-child:nth-last-child(1) {
				@include media($tablet) {
					width: 50%;

					&.is-fullwidth {
						width: 100%;
					}
				}
			}

			// 2 items
			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ & {
				@include media($tablet) {
					width: 50%;

					&.is-fullwidth {
						width: 100%;
					}
				}
			}

			// 3 items
			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ & {
				@include media($tablet) {
					width: calc(100% / 3);

					&.is-fullwidth {
						width: 100%;
					}
				}
			}

			// 4 items
			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ & {
				@include media($tablet) {
					width: 50%;

					&.is-fullwidth {
						width: 100%;
					}
				}

				@include media($ipad-land) {
					width: 25%;

					&.is-fullwidth {
						width: 100%;
					}
				}
			}
		}
	}
}
