.copyright {
	background-color: var(--grey-light);
	padding-top: 35px;

	&-row {
		gap: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0;
		border-top: 1px solid #e0e0e0;

		&-col {
			color: #727272;
			font-size: 14px;
			line-height: 1;
		}
	}

	&-link {
		border-bottom: 1px solid transparent;
		transition: border 0.3s ease-out;

		&:hover {
			border-color: currentColor;
		}

		&.mod-underline {
			border-color: currentColor;

			&:hover {
				border-color: transparent;
			}
		}
	}

	&-divider {
		margin: 0 10px;
	}
}
