.notfound {
	position: relative;

	&-content {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: 150px 0;
		min-height: calc(100vh - 460px);

		&-title {
			margin: 0 auto;
		}

		&-text,
		&-button {
			margin-top: 25px;
		}
	}
}
