@import 'style';

.azlist {
	position: relative;
	margin-bottom: 70px;

	&-content {
		@include content-line;

		&-items {
			margin-top: 40px;

			@include media($tablet) {
				column-count: 2;
				column-gap: 50px;
			}

			@include media($ipad-land) {
				column-count: 3;
			}

			&-item {
				break-inside: avoid-column;

				& + & {
					margin-top: 30px;
				}

				&-alphabet {
					font-size: 46px;
					line-height: 50px;
					font-weight: 600;
					color: var(--primary);
					margin-bottom: 5px;
				}

				div + div {
					margin-top: 5px;
				}
			}
		}
	}
}
