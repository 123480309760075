@mixin m-lineclamp($lines: 3, $line-height: 1.5) {
	line-height: $line-height;
	max-height: ($lines * $line-height) + em;
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
}

@mixin m-lineheight($lines: 3, $line-height: 1.5) {
	line-height: $line-height;
	max-height: ($lines * $line-height) + em;
}
