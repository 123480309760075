.findclubfacility {
	display: block;

	&-subtitle,
	&-title,
	&-distance {
		line-height: 1;
		display: inline-block;
		vertical-align: top;
		border-bottom: 1px solid transparent;
		transition: border 0.3s ease-out;
	}

	&-title {
		font-weight: 600;
		border-width: 2px;
	}

	&:hover,
	&:focus-visible {
		.facility-subtitle,
		.facility-title {
			border-color: currentColor;
		}
	}

	& + & {
		margin-top: 20px;
	}
}
