.pagination {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 30px;
	margin-top: 60px;
	margin-bottom: 50px;

	&-prev,
	&-next {
		color: #c1c1c1;
		transition: color 0.3s ease-out;

		&:hover {
			color: black;
		}
	}

	&-link {
		color: #c1c1c1;
		font-size: 20px;
		line-height: 1;
		font-weight: 600;
		transition: color 0.3s ease-out;

		&:hover,
		&.is-active {
			color: black;
		}
	}
}
