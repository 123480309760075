@import 'style';
@import 'styles/mixins';

.labelvalue {
	@include media($tablet) {
		gap: 4px;
		display: flex;
	}

	&-value {
		white-space: pre-line;

		&.mod-lineclamp {
			line-height: 1.25;
			@include m-lineclamp(2, 1.25);
		}
	}

	&-label {
		display: block;
	}

	& + & {
		margin-top: 8px;
	}
}
