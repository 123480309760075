@import 'style';

.result {
	&-title {
		&-text {
			display: inline-block;
			margin-right: 0.75rem;
			vertical-align: middle;
			margin-bottom: 5px;
		}
	}
	&-type {
		display: inline-block;
		background-color: var(--primary);
		padding: 0.25rem 0.5rem;
		margin-right: 0.75rem;
		color: white;
		font-weight: 500;
		vertical-align: middle;
		margin-bottom: 5px;
	}
	&-date {
		display: inline-block;
		color: var(--link);
		line-height: 1;
		font-weight: 600;
		vertical-align: middle;
		margin-bottom: 5px;
	}
	&-text {
		line-height: 1.25;
		margin-top: 5px;
	}
}
