@import 'style';

.results {
	margin-top: 100px;
	&-items {
		&-item {
			& + & {
				margin-top: 40px;
			}
		}
	}
	&-empty {
		font-style: italic;
		margin-top: 60px;
		font-size: 1.25em;
		color: #999;
		padding-bottom: 50px;
		min-height: 110px;
	}

	&.mod-documents {
		margin-top: 20px;
		.results-items-item + .results-items-item {
			margin-top: 10px;
		}
	}
}
