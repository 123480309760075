@import 'style';

.image {
	position: relative;

	&-caption {
		padding: 10px;
		padding-bottom: 0;
	}

	&.mod {
		&-fiftypercent {
			@include media($tablet) {
				width: 50%;
				margin-left: auto;
				margin-right: auto;

				.image-caption {
					text-align: center;
				}
			}
		}

		&-sixtysixpercentheight {
			.image-sixtysixpercentheight {
				position: relative;
				width: 100%;
				padding-top: 44.42%;
				overflow: hidden;

				img {
					position: absolute;
					top: 0;
					transform: translateY(-16.665%);
				}
			}
		}
	}
}
