.iframe {
	position: relative;

	&.mod-aspectratio {
		iframe {
			width: 100%;
			height: auto;
			aspect-ratio: 16/9;
		}
	}
}
