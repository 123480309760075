@import 'style';

.videotext {
	&-title {
		margin-bottom: 15px;
	}

	&-row {
		@include media($ipad-land) {
			display: flex;
			align-items: center;
		}

		&-media + &-content {
			margin-top: 15px;

			@include media($ipad-land) {
				margin-top: 0;
				margin-left: 30px;
			}
		}

		&-media {
			flex: 0 0 auto;
			width: 100%;

			&:has(+ .videotext-row-content) {
				@include media($ipad-land) {
					max-width: 560px;
				}
			}
		}

		&-content {
			flex: 1 1 auto;

			&-text + &-links {
				margin-top: 15px;
			}

			&-links {
				&-link + &-link {
					margin-top: 5px;
				}
			}
		}
	}
}

:global(.contentside) {
	.videotext {
		&-row {
			flex-direction: column;

			&-media {
				margin-right: auto;
				max-width: none;
			}

			&-content {
				margin-top: 15px;
				margin-left: 0;
			}
		}
	}
}
