@import 'style';

.mixedsection {
	margin: 75px 0;

	&-row {
		margin-top: -50px;
		margin-left: -50px;

		&-col {
			display: inline-block;
			vertical-align: top;
			width: 100%;
			margin-top: 50px;
			padding-left: 50px;

			&.mod-social {
				margin-top: 30px;
			}

			@include media($tablet) {
				width: 50%;
			}

			@include media($ipad-land) {
				width: calc(100% / 3);
			}
		}
	}
}

.sponsors {
	position: relative;

	&-item {
		text-align: center;

		&-link {
			display: inline-block;
			vertical-align: top;

			&-img {
				width: auto;
				max-height: 65px;
			}
		}

		& + & {
			margin-top: 20px;
		}
	}
}

.changes {
	position: relative;

	&-items {
		margin-top: 10px;

		&-item {
			display: flex;
			align-items: center;
			font-size: 18px;
			font-weight: 400;
			transition: color 0.3s ease-out;

			&:hover {
				color: var(--secondary);
			}

			&-date {
				flex: 0 0 60px;
			}

			&-title {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
}
