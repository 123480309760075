@import 'style';

.hero {
	&-block {
		position: relative;

		&.mod-image {
			display: flex;
			align-items: flex-end;
			padding: 20px;
			min-height: 279px;
			background-color: var(--primary);
			color: white;
			transition: background-color 0.3s ease-out;
		}

		&-content {
			position: relative;
		}
	}
}

.image {
	filter: grayscale(100%);
	mix-blend-mode: multiply;
}
