@import 'style';

.headernav {
	display: none;
	margin-top: -10px;

	@include media($ipad-land) {
		display: block;
	}

	&-item {
		display: inline-block;
		vertical-align: top;
		margin-top: 10px;
		padding-left: 30px;

		&-category {
			outline: none;
			position: relative;
			display: block;
			padding: 24px 2px;
			font-size: 15px;
			font-weight: 600;
			text-transform: uppercase;

			&:before {
				content: '';
				display: block;
				position: relative;
				top: -8px;
				height: 4px;
				background-color: var(--primary);
				transform-origin: bottom right;
				transform: scaleX(0);
				transition: transform 0.3s ease-out;

				.mod-subnavigation & {
					background-color: white;
				}
			}

			&.is-active:before {
				transform-origin: bottom left;
				transform: scaleX(1);
			}
		}

		&:hover,
		&:focus-within {
			.headernav-item-category:before {
				transform-origin: bottom left;
				transform: scaleX(1);
			}

			.dropdown {
				display: block;
			}
		}
	}
}

.dropdown {
	display: none;
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 0;
	width: 100%;
	padding: 30px 0;
	background-color: #f4f4f4;

	&-description {
		font-size: 14px;
		margin-bottom: 20px;
	}

	&-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: -40px;
		margin-left: -20px;

		&-col {
			margin-top: 40px;
			padding-left: 20px;
			width: calc(100% / 3);

			@include media($ipad-land) {
				width: clamp(150px, calc(100% / 3), calc(100% / 6));
			}
		}
	}
}

.subcategory {
	&-link {
		font-weight: 600;
		text-transform: uppercase;
		border-bottom: 2px solid transparent;
		transition: border 0.3s ease-out;

		&:hover,
		&:focus-visible,
		&.is-active {
			border-color: currentColor;
		}
	}

	&-sublinks {
		&-item {
			font-size: 15px;
			line-height: 1.25;

			& + & {
				margin-top: 10px;
			}

			&-link {
				border-bottom: 1px solid transparent;
				transition: border 0.3s ease-out;

				&:hover,
				&:focus-visible,
				&.is-active {
					border-color: currentColor;
				}
			}
		}
	}

	&-link + &-sublinks {
		margin-top: 20px;
	}
}

.button {
	display: none;
	background-color: var(--primary);
	color: black;
	padding: 6px 12px;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	transition: 0.3s ease-out;

	@include media($ipad-land) {
		display: block;
	}

	.mod-subnavigation & {
		background-color: white;
	}

	&:hover,
	&:focus-visible {
		color: white;
		background-color: black;
	}
}

.search {
	font-size: 18px;
	margin-right: 20px;
	transition: color 0.3s ease-out;

	@include media($ipad-land) {
		margin-right: 0;
	}

	&:hover,
	&:focus-visible {
		color: var(--primary);

		.mod-subnavigation & {
			color: white;
		}
	}
}

.button + .search {
	margin-left: 30px;
}
