@import 'style';

.findclubform {
	position: relative;

	@include media($ipad-land) {
		display: flex;
		flex-direction: row-reverse;
	}

	&-map {
		position: relative;
		background-color: var(--grey-light);
		display: block;
		aspect-ratio: 3/2;
		margin-bottom: 30px;

		@include media($ipad-land) {
			width: calc(60% - 30px);
			margin-bottom: 0;
			margin-left: 60px;
		}
	}

	&-content {
		@include media($ipad-land) {
			width: calc(40% - 30px);
		}

		&-form {
			position: relative;
			display: flex;
			width: 100%;
			margin-bottom: 20px;

			&-input {
				background-color: #e5e5e5;
				width: 100%;
				padding: 6px 20px;
				color: var(--link);
				font-weight: 400;

				&:focus {
					outline-color: var(--primary);
				}
			}

			&-submit {
				background-color: var(--primary);
				padding: 5px 20px;
				margin-left: 20px;
				color: white;
				font-weight: 600;
				text-transform: uppercase;
				transition: background 0.3s ease-out;

				&:hover {
					background-color: var(--secondary);
				}
			}
		}
	}

	&-facilities {
		overflow: auto;
		max-height: 540px;
	}
}
