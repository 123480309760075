@import 'style';

.person {
	&-description {
		margin-top: 15px;
	}
}

.table {
	width: 100%;
	border-spacing: 4px;
	margin-top: 40px;

	tr {
		&:nth-child(odd) {
			background-color: var(--primary);
		}

		&:nth-child(even) {
			background-color: hsl(var(--primary-hsl), 0.25);
		}

		td {
			padding: 5px 10px;
		}
	}

	a {
		color: var(--link);
		font-weight: 600;
		display: inline;
		border-bottom: 1px solid transparent;
		transition: border 0.3s ease-out;

		&:hover {
			border-color: currentColor;
		}
	}
}
