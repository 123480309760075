@import 'styles/mixins';

.card {
	display: block;

	&-image {
		position: relative;
		display: flex;
		align-items: flex-end;
		aspect-ratio: 344/185;

		&-overlay {
			background-color: var(--primary);
			position: absolute;
			inset: 0;
			opacity: 0;
			transition: opacity 0.3s ease-out;
		}

		&-content {
			position: relative;
			padding: 20px;
			color: white;
			text-shadow: 0 0 4px rgb(0 0 0 / 20%);
			opacity: 0;
			transition: opacity 0.3s ease-out;

			&-item {
				line-height: 1.25;
				@include m-lineclamp(2, 1.25);

				& + & {
					margin-top: 5px;
				}
			}
		}

		&:hover {
			.card-image {
				&-overlay {
					opacity: 0.8;
				}

				&-content {
					opacity: 1;
				}
			}
		}
	}

	&-title {
		margin-top: 15px;
	}
}
