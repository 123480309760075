@import 'style';

.news {
	position: relative;

	&-content {
		margin: 50px 0;
		@include content-line;

		&-title {
			color: var(--primary);
		}
	}
}
